<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />

    <div>
        
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />

        <v-row :style="`margin-top:10px;direction:`+lang.dir+`;padding-inline:40px;`">
            <v-col cols="12" class="text-end">
                <b-button id="multiply-button" :class="`mt-10 multiply-button-`+lang.lalgin" 
                :style="`width:150px;background:transparent;border:none`" 
                @click="resetfixed()"
                v-b-toggle.add_fixed_assets>
              <div style="width:150px;color:#000;white-space: nowrap" :class="`multiply-button-content-`+lang.lalgin"> {{ lang.add_fixed_assets }}</div></b-button>
            </v-col>
        </v-row>
        <v-simple-table class="mt-1" :style="`padding-inline:40px;direction: ${lang.dir};`">
            <thead>
                <tr>
                    <th class="backBlack text-center">{{ lang.department }}</th>
                    <th class="backBlack text-center">{{ lang.fixed_assets }}</th>
                    <th class="backBlack text-center">{{ lang.purchase_date }}</th>
                    <th class="backBlack text-center">{{ lang.purchase_price }}</th>
                    <th class="backBlack text-center">{{ lang.depreciation_period }}</th>
                    <th class="backBlack text-center">{{lang.qtty}}</th>
                    <th class="backBlack text-center">{{lang.total}}</th>
                    <th class="backBlack text-center">{{ lang.depreciation_rate }}</th>
                    <th class="backBlack text-center">{{ lang.depreciation_amount }}</th>
                    <th class="backBlack text-center">{{ lang.current_price }}</th>
                    <th class="backBlack text-center">{{lang.action}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in paytypesrows" :key="index">
                    <td>{{item.group_name}}</td>
                    <td>{{item.namear}}</td>
                    <td>{{item.purchase_date}}</td>
                    <td>{{item.pprice}}</td>
                    <td>{{item.yers}}</td>
                    <td>{{item.qtty}}</td>
                    <td>{{item.total}}</td>
                    <td>{{item.dep_percentage}}</td>
                    <td>{{item.depamount}}</td>
                    <td>{{item.current}}</td>
                    <td style="width:100px;background:#000;border-bottom:1px solid #fff !important;padding:1px">
                        <b-button class="btn-sm btn-default" style="color:#FFF;background:#000;width:100px;height:25px !important;margin-left:5px;border:none" @click="edit(item)" v-b-toggle.add_fixed_assets>{{lang.update}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></b-button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th class="backEmpty text-center"></th>
                    <th class="backEmpty text-center"></th>
                    <th class="backEmpty text-center"></th>
                    <th class="backEmpty text-center"></th>
                    <th class="backBlack text-center">{{lang.total}}</th>
                    <th class="backBlack text-center">{{ totals.qty }}</th>
                    <th class="backBlack text-center">{{ totals.total }}</th>
                    <th class="backEmpty text-center"></th>
                    <th class="backEmpty text-center"></th>
                    <th class="backBlack text-center">{{ totals.current }}</th>
                </tr>
            </tfoot>
        </v-simple-table>
        <add-fixed-asstes ref="myfixedassets" />
    </div>
        <Footer />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import AddFixedAsstes from '@/components/addFixedAsstes.vue';
import ExportsPrint from '@/components/exports-print.vue'

export default {
    components: { breadCrumbs, TabsComp, HeaderPortrate,Footer, AddFixedAsstes, ExportsPrint },
    data() {
        return {
            paytypesrows:[]
        }
    },
    created() {
        this.getFixedAsstes();
    },
    methods: {
        resetfixed(){
            this.$refs.myfixedassets.restAll();
            
        },
        edit(item){
            this.$refs.myfixedassets.fillit(item);
            this.$refs.myfixedassets.pagetitle= this.lang.udpate_fixed_assets;
        },
        getFixedAsstes(){
            const post = new FormData();
            post.append('type','getFixed');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                this.paytypesrows = res.results.data;
                
            })
        },
    },
    computed: {
        
        totals: function(){
            let t = {
                qty: 0,
                total: 0,
                current: 0
            }
            for(let i=0;i<this.paytypesrows.length;i++){
                t.qty = parseFloat(t.qty) + parseFloat(this.paytypesrows[i].qtty)
                t.total = parseFloat(t.total) + parseFloat(this.paytypesrows[i].total)
                t.current = parseFloat(t.current) + parseFloat(this.paytypesrows[i].current)
            }
            return t;
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items: function() {
            return {
                    text: this.lang.paytypes,
                    disabled: true,
                    to: '/finance/paytypes',
                }
        },
       
    },
}
</script>